<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="wallets !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0">

        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <!-- Search -->
            <b-col
                cols="12"
                md="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchCommand"
                    class="d-inline-block mr-1"
                    placeholder="Search ..."
                />
              </div>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Count : </label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector w-75 mx-50"
              />
            </b-col>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="wallets"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found !"
        >

          <template #cell(userInfo)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="baseURL+data.item.userInfo.selfieFileData"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.userInfo.userName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userInfo.userId }}</small>
            </b-media>
          </template>

          <template #cell(updateDate)="data">
            {{data.item.updateDate.slice(0,10)}}
            -
            {{data.item.updateDate.slice(11,19)}}
          </template>
          <template #cell(credit)="data">
            <p>{{data.item.credit}} $</p>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup,BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {CandleGetAllRequest} from "@/libs/Api/Candle";
import {TradeGetAllRequest} from "@/libs/Api/Trade";
import {WalletGetAllRequest} from "@/libs/Api/Wallet";
import Helper from "@/libs/Helper";

export default {
  title: 'Wallets List',
  name: 'WalletsList',
  data() {
    return {
      wallets: null,
      totalCount: null,
      showOverlay:false,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      baseURL:Helper.baseUrl+'media/gallery/profile/',
      perPage: 10,
      CategoryName:'',
      TicketType:0,
      state:'',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'userInfo',
          label: 'User'
        },
        {
          key: 'credit',
          label: 'Credit'
        },
        {
          key: 'updateDate',
          label: 'Last Update'
        },
      ],
      searchCommand:'',
      market:'',
      pageNumber: 1,
      count: 10,
      SelectedTicket: null,
    }
  },
  async created() {
    await this.GetAllWallets()
  },
  watch: {
    searchCommand: function () {
      this.GetAllWallets()
    },
    currentPage: function () {
      this.GetAllWallets()
    },
  },
  methods:{
    async GetAllWallets(){
      let _this = this;
      _this.showOverlay = true;
      let walletGetAllRequest = new WalletGetAllRequest(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.count,
        searchCommand: _this.searchCommand
      }
      walletGetAllRequest.setParams(data);
      await walletGetAllRequest.fetch(function (content) {
        _this.wallets = content.wallets;
        _this.totalCount = content.walletsCount;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
}
</script>

<style scoped>

</style>
